import { Form, Select } from 'antd'

import { isValidEmail } from 'helpers/regularExpression/email'
import { EmailWrapper, SelectStyling } from '../../../atom/user-invite'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'

interface props {
  selectedEmails: string[]
  setSelectedEmails: (selectedEmails: string[]) => void
}

const UserEmail: React.FC<props> = ({ selectedEmails, setSelectedEmails }) => {
  const { emailText } = useUserTranslation()

  const handleChange = (value: string[]) => {
    let isValid = true
    value.forEach((email) => {
      if (isValid && !isValidEmail(email)) {
        isValid = false
      }
    })
    if (isValid) {
      setSelectedEmails(Array.from(new Set(value)))
    }
  }

  return (
    <EmailWrapper>
      <Form.Item
        key="email"
        label={<span className="heading-06-regular">{emailText}</span>}
        name="email"
        className="email"
        requiredMark={true}
        validateStatus={''}
        validateTrigger={['onChange', 'onBlur']}
        rules={[{ required: true, message: '' }]}
      >
        <SelectStyling disabled={false}>
          <Select
            mode="tags"
            style={{ marginLeft: '0px' }}
            placeholder={emailText}
            value={selectedEmails}
            onChange={handleChange}
            tokenSeparators={[',']}
            open={false}
          />
        </SelectStyling>
      </Form.Item>
    </EmailWrapper>
  )
}

export default UserEmail
