import { Form, Radio } from 'antd'

import { HorizontalLine } from 'atom/horizontal-line'
import { CoreRadioButton } from 'components/button'
import { useUserTranslation } from 'hooks/translation/useUserTranslation'

interface props {
  selectedRole: 'driver' | 'swtch_os_user'
  setSelectedRole: (selectedRole: 'driver' | 'swtch_os_user') => void
}

const UserInviteRole: React.FC<props> = ({ selectedRole, setSelectedRole }) => {
  const { roleTxt } = useUserTranslation()
  return (
    <>
      <HorizontalLine />
      <Form.Item
        name="role"
        label={<span className="heading-06-regular">{roleTxt}</span>}
        style={{ marginTop: '16px' }}
      >
        <Radio.Group
          style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          buttonStyle="solid"
          value={selectedRole}
          onChange={(e) => {
            setSelectedRole(e.target.value)
          }}
        >
          <CoreRadioButton value="driver" label="Driver" checked={selectedRole === 'driver'} />
          <CoreRadioButton value="swtch_os_user" label="SWTCH OS User" checked={selectedRole === 'swtch_os_user'} />
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default UserInviteRole
