import { Radio } from 'antd'
import styled from 'styled-components'

interface props {
  value: string
  label: string
  checked: boolean
  onClick?: () => void
}

const RadioWrapper = styled.div<{ $isChecked: boolean }>`
  width: calc(50% - 6px);
  padding: 12px 24px;
  border-radius: 8px;
  background-color: ${({ theme, $isChecked }) => ($isChecked ? `${theme.colors.primary}0D` : 'transparent')};
  border: 1px solid ${({ theme, $isChecked }) => ($isChecked ? theme.colors.primary : theme.colors.lightGray)};
  cursor: pointer;
  // Make sure the entire area is clickable and radio gets checked
  label {
    width: 100%;
    margin: 0;
  }
  .ant-radio-wrapper {
    width: 100%;
  }
`

export const CoreRadioButton: React.FC<props> = ({ value, label, checked, onClick }) => {
  const handleWrapperClick = (e: React.MouseEvent) => {
    // Find and click the actual radio input
    const radioInput = e.currentTarget.querySelector('input[type="radio"]')
    if (radioInput) {
      ;(radioInput as HTMLElement).click()
    }
    onClick?.()
  }

  return (
    <RadioWrapper $isChecked={checked} onClick={handleWrapperClick}>
      <Radio value={value}>
        <span className="paragraph-01-regular">{label}</span>
      </Radio>
    </RadioWrapper>
  )
}

export default CoreRadioButton
