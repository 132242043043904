import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Row, Spin, Switch, Tooltip } from 'antd'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useSelector } from 'hooks/useSelector'
import { AccessPlan } from 'models/access-plan'
import { DiscountSchema } from 'models/discount'
import React from 'react'
import { GetAllAccessPlans } from 'services/data-provider/access-plan'
import { FindTenants } from 'services/data-provider/tenants'
import { theme } from 'theme'
import { addSVG } from '../../../assets/svg/add'
import { HorizontalLine } from '../../../atom/horizontal-line'
import { SvgSpacing, SwitchLabel } from '../../../atom/user-edit'
import {
  AddMoreWrapper,
  AdminViewBody,
  AdminViewInputBody,
  LabelWrapper,
  MobileBody,
  PermissionWrapper,
  TenantInputBodyAdminView,
  TenantInputMobileBody,
} from '../../../atom/user-invite'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { TenantRef } from '../../../models/tenant'
import { UserPermissions, userInviteInfo } from '../../../models/user'
import { useAppState } from '../../../state'
import { AccessPlanObj } from '../user-edit-redesign'
import { trashcanSVG } from 'assets/svg/trashcan'

interface props {
  userPermissions: UserPermissions[]
  loading: boolean
  allAccessPlans: AccessPlanObj
  setUserPermissions: React.Dispatch<React.SetStateAction<UserPermissions[]>>
  setAllAccessPlans: (allAccessPlans: { [key: number]: AccessPlan[] }) => void
  isNormalUser?: boolean
  isInvite?: boolean
  role?: 'driver' | 'swtch_os_user'
}

const EditForm: React.FC<props> = ({
  userPermissions,
  loading,
  allAccessPlans,
  setUserPermissions,
  setAllAccessPlans,
  isNormalUser = false,
  role,
  isInvite = false,
}) => {
  const [form] = Form.useForm()
  const {
    tenantsText,
    driverText,
    roleText,
    managerText,
    viewerText,
    chargerOperatorText,
    addPermissionsText,
    tooltipText,
    discountPlanText,
  } = useUserTranslation()
  const { accessPlanText } = useTenantTranslation()
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const {
    siteLabelCleaner,
    multiDiscountDebounceFetcher,
    multiHandlePlaceholder,
    multiHandleChange,
    renderMultiOptions,
    multiAccessPlanHandleValue,
    generalDebounceFetcher,
    handleSingleSelectChange,
    renderSingleOption,
  } = useSelector()
  const isMobile = IsMobile()
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: theme.colors.primary }} spin />
  //To filter out charger operator if the user is not admin
  const userPermissionInfo = userInviteInfo.filter(
    (item) => currentUser?.role === 'admin' || item !== 'charger_operator',
  )
  const currentUserTenantAccesses = currentUser?.accesses
    ?.filter(({ resourceType, permissions }) => resourceType === 'Tenant' && permissions?.length)
    .map(({ resourceId }) => Number(resourceId))

  const roleTextTransform = {
    none: '-',
    manager: managerText,
    charger_operator: chargerOperatorText,
    viewer: viewerText,
  }

  const updateUserPermissions = (index: number, updates: Partial<UserPermissions>) => {
    setUserPermissions((prev) => {
      const updatedPermissions = [...prev]
      updatedPermissions[index] = { ...updatedPermissions[index], ...updates }
      return updatedPermissions
    })
  }

  const handleRoleChange = (index: number) => (access: { value: string; label: string }) => {
    updateUserPermissions(index, { access: access?.value })
  }

  const handleDriverChecked = (index: number) => () => {
    updateUserPermissions(index, { driver: !userPermissions[index].driver })
  }

  const handleDiscount = (index: number) => (discountPlan: DiscountSchema[]) => {
    updateUserPermissions(index, { discountPlan })
  }

  const handleAccessPlan = (index: number) => (accessPlan: AccessPlan[]) => {
    updateUserPermissions(index, { accessPlan })
  }

  const handleTenantChange = (index: number) => (tenantData?: TenantRef) => {
    const tenantId = tenantData?.id
    if (tenantId && !allAccessPlans[tenantId]) {
      GetAllAccessPlans(isMockUpEnabled, Number(tenantId))
        .then((resp) => {
          if (!('error' in resp)) {
            setAllAccessPlans({ ...allAccessPlans, [tenantId]: resp })
          }
        })
        .catch((err) => console.log(err))
    }
    updateUserPermissions(index, {
      name: tenantData ? tenantData.combineName : '',
      id: tenantId ?? '',
      discountPlan: [],
      accessPlan: [],
    })
  }

  const addMore = () =>
    setUserPermissions((prevPermissions) => [...prevPermissions, { name: '', id: '', access: '', driver: false }])

  const removeSite = (index: number) => () => {
    const updatedUserPermissions = [...userPermissions]
    updatedUserPermissions.splice(index, 1)
    setUserPermissions(updatedUserPermissions)
  }

  const ContainerWrapper: React.FC = ({ children }) =>
    isMobile ? (
      <TenantInputMobileBody>{children}</TenantInputMobileBody>
    ) : (
      <AdminViewInputBody>
        <TenantInputBodyAdminView isNormalUser={isNormalUser}>{children}</TenantInputBodyAdminView>
      </AdminViewInputBody>
    )

  const AccessWrapper: React.FC<{ index: number }> = ({ children, index }) =>
    isMobile ? (
      <MobileBody key={`edit-${index}`}>
        <>
          {!isInvite && <HorizontalLine customMargin="0px -24px 24px" />}
          {children}
        </>
      </MobileBody>
    ) : isNormalUser ? (
      <div key={`edit-${index}`}>{children}</div>
    ) : (
      <PermissionWrapper key={`edit-${index}`}>{children}</PermissionWrapper>
    )

  const FirstRowWrapper: React.FC = ({ children }) =>
    isMobile ? <>{children}</> : <div style={{ display: 'flex' }}>{children}</div>

  return (
    <Form form={form} labelAlign="right" layout="vertical">
      <>
        {!isMobile && <HorizontalLine />}
        <Spin spinning={loading} indicator={antIcon} style={{ background: 'white', top: '7px' }}>
          <ContainerWrapper>
            {userPermissions.map(
              (data, index) =>
                (currentUser?.role === 'admin' || currentUserTenantAccesses?.includes(Number(data.id)) || !data.id) && (
                  <>
                    {isInvite && (
                      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <span className="heading-06-regular">{`Site ${index + 1}`}</span>
                        <Button type="link" onClick={removeSite(index)} style={{ padding: 0 }}>
                          {trashcanSVG}
                        </Button>
                      </Row>
                    )}

                    <AccessWrapper index={index}>
                      <FirstRowWrapper>
                        <Form.Item
                          className="tenant-select"
                          style={isInvite && role === 'driver' ? { width: '100%', marginRight: '8px' } : {}}
                          label={<LabelWrapper className="regular-cap">{tenantsText}</LabelWrapper>}
                        >
                          <ReusableSelector
                            showSearch
                            onOptionsChange={handleTenantChange(index)}
                            defaultValues={data.name ? [data.name] : undefined}
                            dropDownList={data.name ? [{ combineName: data.name }] : []}
                            isSingle
                            noDefaultSearchText
                            placeholder={tenantsText}
                            showMagnifySVG={false}
                            maxTagCount={0}
                            isDebounceFetcher={true}
                            debounceFetcher={generalDebounceFetcher(
                              (value) => FindTenants(currentUser, false, isMockUpEnabled, value),
                              true,
                              undefined,
                              1,
                            )}
                            handleOptionChange={handleSingleSelectChange('combineName')}
                            renderOption={renderSingleOption(
                              {
                                val: 'combineName',
                                label: 'combineName',
                              },
                              false,
                              siteLabelCleaner,
                            )}
                            isMockUpEnabled={isMockUpEnabled}
                          />
                        </Form.Item>
                        {!(isInvite && role === 'driver') && (
                          <Form.Item
                            className="role-select"
                            key="role"
                            label={<LabelWrapper className="regular-cap">{roleText}</LabelWrapper>}
                          >
                            <ReusableSelector
                              showSearch
                              noDefaultSearchText
                              onOptionsChange={handleRoleChange(index)}
                              defaultValues={data.access ? [data.access] : []}
                              dropDownList={userPermissionInfo.map((role) => ({
                                value: role,
                                label: roleTextTransform[role],
                              }))}
                              isSingle
                              clearSearchAfterSelect
                              oneTimeUpdateDefaultValues
                              customWidth={isNormalUser && !isMobile ? '120px' : undefined}
                              placeholder={roleText}
                              showMagnifySVG={false}
                              maxTagCount={0}
                              isDebounceFetcher={false}
                              handleOptionChange={handleSingleSelectChange('value')}
                              renderOption={renderSingleOption({
                                val: 'value',
                                label: 'label',
                              })}
                              isMockUpEnabled={isMockUpEnabled}
                            />
                          </Form.Item>
                        )}
                        {isNormalUser && !isMobile && !isInvite && (
                          <Form.Item
                            className="driver"
                            key="driver"
                            label={
                              <>
                                <LabelWrapper className="regular-cap">{driverText}</LabelWrapper>
                                <Tooltip
                                  overlayInnerStyle={{ borderRadius: '5px' }}
                                  placement="top"
                                  title={tooltipText}
                                >
                                  <ExclamationCircleFilled
                                    style={{
                                      fontSize: theme.fontSizes[3],
                                      marginLeft: '5px',
                                      color: theme.colors.lightGray,
                                    }}
                                  />
                                </Tooltip>
                              </>
                            }
                          >
                            <div>
                              <SwitchLabel>Off</SwitchLabel>
                              <Switch
                                style={{
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                  backgroundColor: data.driver ? theme.colors.primary : '#bfbfbf',
                                }}
                                onChange={handleDriverChecked(index)}
                                checked={data.driver}
                              />
                              <SwitchLabel>On</SwitchLabel>
                            </div>
                          </Form.Item>
                        )}
                      </FirstRowWrapper>
                      {isMobile && !isInvite ? (
                        <Form.Item
                          className="switch"
                          key="driver"
                          label={
                            <>
                              {driverText}
                              <Tooltip
                                overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                                placement="top"
                                title={tooltipText}
                              >
                                <ExclamationCircleFilled
                                  style={{
                                    fontSize: theme.fontSizes[3],
                                    marginLeft: '5px',
                                    color: theme.colors.lightGray,
                                  }}
                                />
                              </Tooltip>
                            </>
                          }
                        >
                          <div>
                            <SwitchLabel>Off</SwitchLabel>
                            <Switch
                              style={{
                                marginLeft: '5px',
                                marginRight: '5px',
                                backgroundColor: data.driver ? theme.colors.primary : '#bfbfbf',
                              }}
                              onChange={handleDriverChecked(index)}
                              checked={data.driver}
                            />
                            <SwitchLabel>On</SwitchLabel>
                          </div>
                        </Form.Item>
                      ) : isNormalUser ? (
                        <></>
                      ) : (
                        (!isInvite || (isInvite && role === 'driver')) && (
                          <AdminViewBody isMobile={isMobile}>
                            <Form.Item
                              className="access-plan-select"
                              label={
                                <LabelWrapper className="regular-cap">
                                  {driverText} {accessPlanText}
                                  <Tooltip
                                    overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                                    placement="top"
                                    title={tooltipText}
                                  >
                                    <ExclamationCircleFilled
                                      style={{
                                        fontSize: theme.fontSizes[3],
                                        marginLeft: '5px',
                                        color: theme.colors.lightGray,
                                      }}
                                    />
                                  </Tooltip>
                                </LabelWrapper>
                              }
                            >
                              <ReusableSelector
                                showSearch
                                onOptionsChange={handleAccessPlan(index)}
                                isSingle={false}
                                placeholder={accessPlanText}
                                customWidth="221px"
                                showMagnifySVG={false}
                                maxTagCount={0}
                                isDebounceFetcher={false}
                                defaultValues={
                                  data.accessPlan
                                    ? data.accessPlan?.filter(
                                        (plan) =>
                                          plan.accessType === 'restricted' ||
                                          (plan as any)['access_type'] === 'restricted',
                                      )
                                    : []
                                }
                                dropDownList={allAccessPlans[data.id]
                                  ?.filter((plan) => plan.accessType === 'restricted')
                                  ?.map((item) => ({ ...item, label: item.name }))}
                                handlePlaceholder={multiHandlePlaceholder('name', accessPlanText)}
                                handleOptionChange={multiHandleChange('id')}
                                renderOption={renderMultiOptions({
                                  val: 'id',
                                  label: 'name',
                                })}
                                handleValue={multiAccessPlanHandleValue}
                                isMockUpEnabled={isMockUpEnabled}
                              />
                            </Form.Item>
                            <Form.Item
                              className="discount-schema-select"
                              key="discount-schema"
                              label={<LabelWrapper className="regular-cap">{discountPlanText}</LabelWrapper>}
                            >
                              <ReusableSelector
                                showSearch
                                onOptionsChange={handleDiscount(index)}
                                defaultValues={data.discountPlan ?? []}
                                dropDownList={data.discountPlan ?? []}
                                isSingle={false}
                                placeholder={discountPlanText}
                                showMagnifySVG={false}
                                maxTagCount={0}
                                isDebounceFetcher={true}
                                fieldForValue="id"
                                handlePlaceholder={multiHandlePlaceholder('name', discountPlanText)}
                                debounceFetcher={multiDiscountDebounceFetcher(parseFloat(data.id))}
                                handleOptionChange={multiHandleChange('id')}
                                renderOption={renderMultiOptions({
                                  val: 'id',
                                  label: 'name',
                                })}
                                isMockUpEnabled={isMockUpEnabled}
                              />
                            </Form.Item>
                          </AdminViewBody>
                        )
                      )}
                    </AccessWrapper>
                  </>
                ),
            )}
          </ContainerWrapper>
          <AddMoreWrapper>
            <Form.Item className="add-more-button">
              <Button
                style={{
                  color: theme.colors.primary,
                  padding: 0,
                  height: 'fit-content',
                  marginBottom: '0px',
                  letterSpacing: '0.05em',
                }}
                type="link"
                onClick={addMore}
              >
                <SvgSpacing>{addSVG}</SvgSpacing>
                {addPermissionsText}
              </Button>
            </Form.Item>
          </AddMoreWrapper>
        </Spin>
      </>
    </Form>
  )
}

const arePropsEqual = (prevProps: props, nextProps: props) => {
  const fieldsToNotRerender = ['accessPlan', 'discountPlan']
  const userPermissionsLengthChanged = prevProps.userPermissions.length !== nextProps.userPermissions.length
  const accessOrDiscountChanged = prevProps.userPermissions.some((prev, index) => {
    const next = nextProps.userPermissions[index]
    return fieldsToNotRerender.some((field) => {
      const prevField = prev?.[field as keyof UserPermissions] || []
      const nextField = next?.[field as keyof UserPermissions] || []
      return JSON.stringify(prevField) !== JSON.stringify(nextField)
    })
  })
  if (!userPermissionsLengthChanged && accessOrDiscountChanged) return true
  else {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  }
}
export const MemoizedEditForm = React.memo(EditForm, arePropsEqual)
