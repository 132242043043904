import { useEffect, useState } from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import { SelectProps } from 'antd/es/select'
import { Button, Divider, Form, FormInstance, Input, message, Select, Space, Tooltip } from 'antd'

import { UserRef } from '../../models/user'
import { TenantRef } from '../../models/tenant'
import { SwtchError } from '../../models/error'
import { ListingStripeAccounts, StripeAccount } from '../../models/stripe-account'

import { GetListingStripeAccounts } from '../../services/data-provider/listing'
import { FindTenants } from '../../services/data-provider/tenants'
import { GetStripeAccounts } from '../../services/data-provider/stripe'

import { useListingAuthorTranslation } from '../../hooks/translation/useListingAuthorTrans'
import { useListingChargerTranslation } from '../../hooks/translation/useListingChargerTrans'

import { AlertError } from '../error'
import { MustBeRequired } from '../rules/rules'
import { useSelector } from 'hooks/useSelector'
import { useAppState } from 'state'

interface props {
  form: FormInstance<any>
  listingAuthor?: UserRef
  tenant?: TenantRef
  stripeAccount?: StripeAccount
}

interface StripeAccountOption {
  value: string
  label: JSX.Element
  stripeAccount: StripeAccount
}
interface AuthorOption {
  value: string
  label: JSX.Element
  authorAccount: ListingStripeAccounts
}

const renderStripeAccount = (stripeAccount: StripeAccount): StripeAccountOption => {
  return {
    value: stripeAccount.name,
    stripeAccount,
    label: <span title={stripeAccount.name}>{`${stripeAccount.name} [${stripeAccount.country || '---'}]`}</span>,
  }
}

const renderAuthorAccount = (authorAccount: ListingStripeAccounts): AuthorOption => {
  return {
    value: authorAccount.id,
    authorAccount,
    label: <span title={authorAccount.name}>{authorAccount.name}</span>,
  }
}

export const ListingAuthorV2: React.FC<props> = ({ form, listingAuthor, tenant, stripeAccount }) => {
  const { isMockUpEnabled, currentUser } = useAppState()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [tenantLoading, setTenantLoading] = useState(false)
  const { siteLabelCleaner } = useSelector()

  const [author, setAuthor] = useState<UserRef | undefined>(listingAuthor)
  const [authorAccounts, setAuthorAccounts] = useState<SelectProps<object>['options']>([])

  const [defaultStripeAccount, setDefaultStripeAccount] = useState<StripeAccount | undefined>(stripeAccount)
  const [stripeAccountOptions, setStripeAccountOptions] = useState<SelectProps<object>['options']>([])

  const [selectedTenant, setSelectedTenant] = useState<TenantRef | undefined>(tenant)
  const [tenants, setTenants] = useState<TenantRef[]>()

  const {
    listingAuthorText,
    refreshAuthorStripeText,
    listingAuthorRequiredText,
    selectListingAuthorText,
    stripeAccountRequiredText,
    selectStripeAccountText,
  } = useListingAuthorTranslation()

  useEffect(() => {
    setTenantLoading(true)
    FindTenants(currentUser, true, isMockUpEnabled)
      .then((tenants) => setTenants(tenants))
      .catch((err: SwtchError) => {
        setTenants([])
        setError(err)
        message.error(err.description)
      })
      .finally(() => setTenantLoading(false))
  }, [])

  useEffect(() => {
    getAuthorAccounts()
  }, [])

  useEffect(() => {
    if (author && selectedTenant) {
      setLoading(true)
      GetStripeAccounts(author?.id, selectedTenant?.id)
        .then((stripeAccounts) => {
          const opts = stripeAccounts.map((stripeAcc) => renderStripeAccount(stripeAcc))
          setStripeAccountOptions(opts)
        })
        .catch((err: SwtchError) => {
          setError(err)
          message.error(err.description)
        })
        .finally(() => setLoading(false))
    }
    return
  }, [author, selectedTenant])

  const getAuthorAccounts = () => {
    setLoading(true)
    GetListingStripeAccounts()
      .then((accounts) => {
        const opts = accounts.map((acc) => renderAuthorAccount(acc))
        setAuthorAccounts(opts)
      })
      .catch((err: SwtchError) => {
        setError(err)
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const handleSelectTenant = (selectedTenant: string) => {
    const filteredTenant = tenants?.find((t) => siteLabelCleaner(t.combineName) === selectedTenant)
    setSelectedTenant(filteredTenant)
    filteredTenant && form.setFieldsValue({ tenantId: Number(filteredTenant.id) })
  }
  const handleSelectAuthor = (selectedAuthor: string) => {
    const filteredAuthor = authorAccounts?.find((author) => author.value === selectedAuthor)
    setAuthor(filteredAuthor?.authorAccount)
    setDefaultStripeAccount(undefined)
    form.setFieldsValue({ stripeAccountName: null })
    filteredAuthor && form.setFieldsValue({ authorId: filteredAuthor.authorAccount.id })
  }

  const { tenantIsRequired, associatedTenanted, searchTenantText } = useListingChargerTranslation()

  const handleStripeAccount = (selectedStripeAccount: string) => {
    const finalStripeAccount = stripeAccountOptions?.find((stripeAccountOption) => {
      return stripeAccountOption.stripeAccount.name === selectedStripeAccount
    })
    setDefaultStripeAccount(finalStripeAccount?.stripeAccount)
    form.setFieldsValue({ stripeAccountId: finalStripeAccount?.stripeAccount.id })
    form.setFieldsValue({ stripeAccount: finalStripeAccount?.stripeAccount.name })
  }

  return (
    <>
      <AlertError error={error} />
      <Divider>Stripe</Divider>
      <Form.Item label={associatedTenanted} name="tenantName" rules={[MustBeRequired(tenantIsRequired)]}>
        <Select
          disabled={tenantLoading}
          loading={tenantLoading}
          showSearch
          style={{ minWidth: 175 }}
          placeholder={searchTenantText}
          dropdownMatchSelectWidth={false}
          onSelect={handleSelectTenant}
        >
          {tenants?.map((tenant) => (
            <Select.Option key={tenant.id} value={siteLabelCleaner(tenant.combineName)}>
              {siteLabelCleaner(tenant.combineName)} {`[${tenant.country || '---'}]`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        style={{ lineHeight: '4rem' }}
        label={
          <Space>
            {listingAuthorText}
            <Tooltip title={refreshAuthorStripeText}>
              <Button shape="circle" type="primary" icon={<ReloadOutlined />} onClick={getAuthorAccounts} />
            </Tooltip>
          </Space>
        }
        name="listingAuthor"
        rules={[MustBeRequired(listingAuthorRequiredText)]}
      >
        <Select
          className="listing-author-selector"
          showSearch
          defaultValue={author?.name}
          disabled={loading}
          style={{ minWidth: 175 }}
          placeholder={selectListingAuthorText}
          dropdownMatchSelectWidth={false}
          options={authorAccounts}
          onSelect={handleSelectAuthor}
          loading={loading}
        />
      </Form.Item>
      <Form.Item hidden name="authorId">
        <span />
      </Form.Item>
      <Form.Item label="Stripe Account" name="stripeAccountName" rules={[MustBeRequired(stripeAccountRequiredText)]}>
        <Select
          className="listing-author-selector"
          showSearch
          disabled={loading}
          defaultValue={defaultStripeAccount?.name}
          style={{ minWidth: 175 }}
          placeholder={selectStripeAccountText}
          dropdownMatchSelectWidth={false}
          onSelect={handleStripeAccount}
          options={stripeAccountOptions}
          loading={loading}
        />
      </Form.Item>
      <Form.Item name="stripeAccountId" hidden>
        <Input disabled />
      </Form.Item>
    </>
  )
}
