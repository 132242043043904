import { useIntl, FormattedMessage } from 'react-intl'

export const useFormatMessage = (messageId: string, defaultMessage: string): string => {
  const intl = useIntl()
  return intl.formatMessage({ id: messageId, defaultMessage })
}

export const renderFormatMessage = (
  messageId: string,
  defaultMessage: string,
  values?: Record<string, any>,
): JSX.Element => {
  return <FormattedMessage id={messageId} defaultMessage={defaultMessage} values={values} />
}

export const getHighlightedText = (text: string, highlight?: string, highlightClass?: string) => {
  if (!highlight) return text
  const parts = text.split(highlight)
  return (
    <>
      {parts[0]}
      <strong className={highlightClass}>{highlight}</strong>
      {parts[1]}
    </>
  )
}

export const getLinkText = (text: string, links: Record<string, string>) => {
  const textToSplit = Object.keys(links)
    .map((link) => `${link}.*?${link}`)
    .join('|')
  return (
    <span>
      {text.split(new RegExp(`(${textToSplit})`)).map((part, index) => {
        let textToReturn
        Object.keys(links).forEach((link) => {
          if (part.startsWith(link)) {
            const linkText = part.replace(new RegExp(link, 'g'), '')
            textToReturn = (
              <a key={index} href={links[link as keyof typeof links]} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
            )
          }
        })
        if (textToReturn) return textToReturn
        // Render regular text
        return <span key={index}>{part}</span>
      })}
    </span>
  )
}
