import { useFormatMessage } from '../../helpers/intl'

export const usePricingSchemasTranslation = () => {
  const hourText = useFormatMessage('dashboard.text.hour', 'Hour')
  const timeText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.time', 'Time')
  const nameInUseText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.nameInUse', 'Name already in use')
  const hoursNotDefinedText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.hoursNotDefined',
    'All hours not defined will follow default rate.',
  )
  const toText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.to', 'TO')
  const loiteringText = useFormatMessage('dashboard.text.loitering', 'Loitering')
  const constantsText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.constants', 'Constants')
  const defineNewCommissionText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.defineNewCommissionFee',
    'Define New Commission Fee',
  )
  const feeText = useFormatMessage('dashboard.text.fee', 'Fee')
  const rateText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.rate', 'Rate')
  const curtailmentAdjustmentText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustment',
    'Curtailment Adjustment',
  )
  const timeOfDayText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.timeOfDay', 'Time of Day')
  const weekdayRateText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.weekdayRate', 'Weekday Rate')
  const weekendRateText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.weekendRate', 'Weekend Rate')
  const loiteringRateText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.loiteringRate', 'Loitering Rate')
  const gracePeriodText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.gracePeriod', 'Grace Period (min)')
  const timeLimitText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.timeLimit', 'Time Limit (min)')
  const timeOfDayLoiteringText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.timeOfDayLoitering',
    'Time of Day Loitering',
  )
  const weekdayLoiteringRateText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.weekdayLoiteringRate',
    'Weekday Loitering Rate',
  )
  const weekendLoiteringRateText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.weekendLoiteringRate',
    'Weekend Loitering Rate',
  )
  const paymentProcessingFeeText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.paymentProcessingFee',
    'Payment Processing Fee',
  )
  const commissionFeeText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.commissionFee', 'Commission Fee')
  const newCommissionFeeText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.newCommissionFee',
    'New Commission Fee',
  )
  const curtailmentAdjustmentEnabledText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustmentEnabled',
    'Curtailment Adjustment Pricing Enabled',
  )
  const maxLoiteringText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.maxLoitering', 'Max Loitering')
  const curtailmentAdjustmentInfoText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustmentInfoText',
    'If enabled, hourly rate will be prorated based on output',
  )
  const selectedListingsInfoText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.selectedListingsInfoText',
    'Selected listings will be updated to the new pricing plan',
  )
  const maxLoiteringInfoText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.maxLoiteringInfoText',
    'This is the maximum amount drivers will get charged for loitering.',
  )
  const paymentLimitText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.paymentLimitText', 'Payment Limit')
  const caKwhInfoText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.canadaKwhInfo',
    'By setting kWh pricing, you confirm that you have applied and been approved for Temporary Dispensation by Measurement Canada. You can review <link1>the terms and conditions here<link1> or apply for dispensation directly via the <link2>Measurement Canada website here.<link2> Approval must be provided to you by Measurement Canada prior to updating pricing for chargers.',
  )

  const pricingPlanUpdatedText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.pricingPlan.updated',
    'Pricing schema Updated Successfully',
  )

  const pricingPlanCreatedText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.pricingPlan.created',
    'Pricing schema created successfully',
  )

  const selectedListingsText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.pricingPlan.selectedListings',
    'Selected Chrager(s): ',
  )

  const loiteringInfoP1Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p1',
    'Loitering can be used to encourage efficient use of charging stations. Loitering can be enforced either by Time or by Status.',
  )
  const loiteringInfoP2Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p2',
    'Time-based Loitering is common in public or retail parking spots, where the aim is to optimize the usage of charging stations.',
  )
  const loiteringInfoP3Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p3',
    'You can designate the Loitering Rate and the Time Limit for when the loitering rate will start.',
  )
  const loiteringInfoP4Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p4',
    'Example: After a driver has been charging for two hours, then they will start being charged $5 hour for charging.',
  )
  const loiteringInfoP5Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p5',
    'Status-based Loitering can be used to encourage a driver to leave the spot after their vehicle reaches a “fully charged status”.',
  )
  const loiteringInfoP6Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p6',
    'You can designate a grace period to allow the driver to have time to move their car once they receive the notification that their vehicle is fully charged.',
  )
  const loiteringInfoP7Text = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.loiteringInfo.p7',
    'Example: After a driver is fully charged and they receive the notification, they have 20 minutes to move their vehicle. After 20 mins, if their car remains plugged in, then they will start being charged $5 hour.',
  )
  const associatedPricingPlanNoteText = useFormatMessage(
    'dashboard.tenantPage.pricingSchemaTab.associatedPricingPlan.note',
    'Note: These chargers have a discount plan. If you require changes to the discounts, please contact us at <email>',
  )

  return {
    hourText,
    timeText,
    nameInUseText,
    hoursNotDefinedText,
    toText,
    loiteringText,
    constantsText,
    defineNewCommissionText,
    feeText,
    rateText,
    curtailmentAdjustmentText,
    timeOfDayText,
    weekdayRateText,
    weekendRateText,
    loiteringRateText,
    gracePeriodText,
    timeLimitText,
    timeOfDayLoiteringText,
    weekdayLoiteringRateText,
    weekendLoiteringRateText,
    paymentProcessingFeeText,
    commissionFeeText,
    newCommissionFeeText,
    curtailmentAdjustmentEnabledText,
    maxLoiteringText,
    curtailmentAdjustmentInfoText,
    selectedListingsInfoText,
    maxLoiteringInfoText,
    paymentLimitText,
    caKwhInfoText,
    pricingPlanUpdatedText,
    pricingPlanCreatedText,
    selectedListingsText,
    loiteringInfoP1Text,
    loiteringInfoP2Text,
    loiteringInfoP3Text,
    loiteringInfoP4Text,
    loiteringInfoP5Text,
    loiteringInfoP6Text,
    loiteringInfoP7Text,
    associatedPricingPlanNoteText,
  }
}
