import React from 'react'

import { Form, Modal, Select, Space, Table } from 'antd'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { ColumnTypes } from 'components/table/editable-table'
import { useDemandManagementParticipantDetail } from 'hooks/useDemandManagementParticipantDetail'
import { useSelector } from 'hooks/useSelector'
import { Participants, PeakShavingProgram } from 'models/peak-shaving'
import { FindListings } from 'services/data-provider/listing'
import { GetAllPeakShavingPrograms } from 'services/data-provider/peak-shaving'
import { FindTenants } from 'services/data-provider/tenants'
import styled from 'styled-components'
import { closeSVG } from '../../../assets/svg/close'
import { PeakShavingSelectArrowDown } from '../../../assets/svg/select'
import { ButtonModal } from '../../../atom/button'
import { layout } from '../../../atom/form/page-layout'
import { AlertError } from '../../../components/error'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { useAppState } from 'state'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit: (updatedProgram?: PeakShavingProgram) => void
  initialValues?: Participants //This indicates Edit modal
}

const DemandManagementAddParticipantModalContainer = styled.div`
  .ant-modal-footer {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-footer .ant-btn {
    min-width: 240px;
  }
`

const TableSection = styled.div`
  margin-bottom: 20px;
`

export const DemandManagementAddParticipantModal: React.FC<props> = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const { isMockUpEnabled, currentUser } = useAppState()
  const {
    cancelText,
    submitText,
    isRquiredText,
    listingsText,
    addParticipantsText,
    editParticipantsText,
    tenantText,
    allListingsText,
    listingsHelperText,
    programTabProgramSelectorText,
  } = usePeakShavingTranslation()

  const {
    generalDebounceFetcher,

    handleSingleSelectChange,
    renderSingleOption,

    renderMultiOptions,
    multiHandlePlaceholder,
    multiHandleChange,
    renderMultiProgramsOptions,
    siteLabelCleaner,
  } = useSelector()

  const {
    loading,
    handleFormSubmit,
    error,
    form,
    allListingOptions,
    formItemLayout,
    onSelect,
    setAllListingStatus,
    allListingStatus,
    tableColumns,
    dataSource,
  } = useDemandManagementParticipantDetail(onSubmit, onCancel, initialValues)

  return (
    <DemandManagementAddParticipantModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{initialValues ? editParticipantsText : addParticipantsText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          <Space>
            <ButtonModal type="ghost" icon={<></>} click={onCancel} text={cancelText} />
            <ButtonModal type="primary" icon={<></>} click={handleFormSubmit} text={submitText} />
          </Space>
        }
        onCancel={onCancel}
        width={540}
        className={`createNewProgramModal ${error ? 'ant-modal-error' : ''}`}
        closeIcon={closeSVG}
      >
        <AlertError error={error} />
        <Form
          {...layout}
          form={form}
          style={{ marginTop: '2rem' }}
          initialValues={{
            ...initialValues,
            allListing:
              initialValues?.allListing !== undefined
                ? allListingOptions[initialValues.allListing ? 1 : 0].value
                : undefined,
          }}
          layout="vertical"
        >
          <Form.Item
            label={<p className="paragraph-02-regular">{tenantText}</p>}
            name="tenant"
            rules={[{ required: true, message: isRquiredText.replace('-1', tenantText) }]}
            {...formItemLayout}
          >
            <ReusableSelector
              showSearch
              isSingle
              // Future implementation for Edit participant
              //   defaultValues={
              //     initialValues ? [initialValues.tenant?.displayName || initialValues.tenant?.name] : undefined
              //   }
              onClear={() => onSelect('tenant')(undefined)}
              showMagnifySVG={false}
              showArrow={false}
              maxTagCount={0}
              isDebounceFetcher={true}
              placeholder={tenantText}
              handleOptionChange={handleSingleSelectChange('combineName')}
              onOptionsChange={onSelect('tenant')}
              debounceFetcher={generalDebounceFetcher(
                (value) => FindTenants(currentUser, false, isMockUpEnabled, value),
                true,
                undefined,
                1,
              )}
              renderOption={renderSingleOption(
                {
                  val: 'combineName',
                  label: 'combineName',
                },
                false,
                siteLabelCleaner,
              )}
              isMockUpEnabled={isMockUpEnabled}
            />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{allListingsText}</p>}
            name="allListing"
            help={listingsHelperText}
            rules={[{ required: true, message: isRquiredText.replace('-1', allListingsText) }]}
            {...formItemLayout}
          >
            <Select
              options={allListingOptions}
              suffixIcon={<PeakShavingSelectArrowDown />}
              onSelect={setAllListingStatus}
            />
          </Form.Item>
          {allListingStatus === 'selectListings' && (
            <>
              <Form.Item
                label={<p className="paragraph-02-regular">{listingsText}</p>}
                name="listings"
                rules={[{ required: true, message: isRquiredText.replace('-1', listingsText) }]}
                {...formItemLayout}
              >
                <ReusableSelector
                  showSearch
                  placeholder={listingsText}
                  showMagnifySVG={false}
                  maxTagCount={0}
                  fieldForValue="id"
                  isDebounceFetcher={true}
                  onClear={() => onSelect('listings')(undefined)}
                  // Future implementation for Edit participant
                  // defaultValues={form.getFieldValue('listings')}
                  handlePlaceholder={multiHandlePlaceholder('title', listingsText)}
                  onOptionsChange={onSelect('listings')}
                  debounceFetcher={generalDebounceFetcher(
                    (value) => FindListings(isMockUpEnabled, value, form.getFieldValue('tenant')),
                    true,
                    undefined,
                    2,
                  )}
                  handleOptionChange={multiHandleChange('id')}
                  renderOption={renderMultiOptions({
                    val: 'id',
                    label: 'title',
                  })}
                  isMockUpEnabled={isMockUpEnabled}
                />
              </Form.Item>
              {!!dataSource.length && (
                <TableSection>
                  <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={tableColumns as ColumnTypes}
                    rowClassName={() => 'editable-row'}
                  />
                </TableSection>
              )}
            </>
          )}
          <Form.Item
            label={<p className="paragraph-02-regular">{programTabProgramSelectorText}</p>}
            name="programs"
            {...formItemLayout}
          >
            <ReusableSelector
              showSearch
              nativeSearch
              isSingle={false}
              showArrow={false}
              onClear={() => onSelect('programs')(undefined)}
              clearSearchAfterSelect
              placeholder={programTabProgramSelectorText}
              // Future implementation for Edit participant
              // defaultValues={initialValues?.programs}
              // dropDownList={initialValues?.programs}
              showMagnifySVG={false}
              fieldForValue="id"
              maxTagCount={0}
              isDebounceFetcher={true}
              handlePlaceholder={multiHandlePlaceholder('name', programTabProgramSelectorText)}
              debounceFetcher={generalDebounceFetcher(
                (programName) =>
                  GetAllPeakShavingPrograms(isMockUpEnabled, {
                    programName,
                  }),
                true,
                'data',
              )}
              preload
              handleOptionChange={multiHandleChange('id')}
              renderOption={renderMultiProgramsOptions}
              onOptionsChange={onSelect('programs')}
              isMockUpEnabled={isMockUpEnabled}
            />
          </Form.Item>
        </Form>
      </Modal>
    </DemandManagementAddParticipantModalContainer>
  )
}
