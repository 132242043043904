import { UserRef } from './user'
import { ListingRef } from './listing'
import { Charger } from './charger'
import { ConnectorV2 } from './connector'
import { TenantRef } from './tenant'
import { CarRef } from './car'
import { Currency } from './plan'

export type ExportType = 'pdf' | 'csv'

export const transactionStartMethods = ['rfid', 'mobile', 'website'] as const
export const transactionStartMethodsArr = ['rfid', 'mobile', 'website']
export type TransactionStartMethod = typeof transactionStartMethods[number]

export const transactionState = ['confirmed', 'free', 'no_reservation', 'paid', 'preauthorized', 'checked_in'] as const
export type TransactionState = typeof transactionState[number]

export const transactionStateV2 = ['canceled', 'completed', 'errored', 'ongoing', 'pending_payment'] as const
export const transactionStateV2Arr = ['canceled', 'completed', 'errored', 'ongoing', 'pending_payment']

export type TransactionStateV2 = typeof transactionStateV2[number]

export const endingStates = ['confirmed', 'errored', 'free'] as const

export const transactionStateV1ToV2 = [...transactionState, ...transactionStateV2] as const

export type TransactionStateV1ToV2 = typeof transactionStateV1ToV2[number]

export const TransactionStateAlias: { [key: string]: TransactionStateV1ToV2[] } = {
  completed: ['completed', 'confirmed', 'free'],
  ongoing: ['no_reservation', 'checked_in', 'ongoing'],
  pending_payment: ['pending_payment'],
  errored: ['errored'],
  canceled: ['canceled'],
}

export interface Transaction {
  id: number
  ocppTransactionId?: number
  percent: number
  percentStart: number | null
  startMethod: string
  currentState: TransactionStateV1ToV2
  currentChargingDuration: number

  startAt: string
  endAt?: string
  chargeStartAt?: string
  chargeEndAt?: string

  timezone: string
  duration: number
  energy: number
  financial: Financial
  charger: Charger
  tenant: TenantRef
  connector: ConnectorV2
  user: UserRef
  listing: ListingRef
  car?: CarRef
  rfidNumber?: string
}

export interface Transactionv2 {
  txnId: number
  startMethod: string
  plugInDuration: number
  connected: string
  disconnected: string
  energy: number
  state: string
  total: number
  username: string
}

export interface Financial {
  subtotal: number
  total: number
  tax: number
  serviceFee: number
  tenantAmount: number
  propertySubtotal: number
  propertyTax: number
  currency: Currency
}

export const hasEnded = (trx: Transaction): boolean => {
  return (
    trx.currentState === 'free' ||
    trx.currentState === 'confirmed' ||
    trx.currentState === 'errored' ||
    trx.currentState === 'completed'
  )
}

export const errorTransaction = (trx: Transaction): boolean => {
  return (
    trx.currentState === 'free' ||
    trx.currentState === 'confirmed' ||
    trx.currentState === 'pending_payment' ||
    trx.currentState === 'ongoing'
  )
}

export const hasErrored = (trx: Transaction): boolean => {
  return trx.currentState === 'canceled' || trx.currentState === 'errored'
}

export const calculateGHGSavings = (energy: number, province?: string) => {
  /* For now, return Canada's electric carbon intensity
  and for the future, uncomment the code below */
  // switch (province) {
  //   case 'ON':
  //     return 1.009 * energy: number;
  //   case 'AB':
  //     return 0.149 * energy: number;
  //   case 'BC':
  //     return 1.038 * energy: number;
  //   case 'QC':
  //     return 1.048 * energy: number;
  //   default: // Canada
  return 0.73 * energy
  // }
}

export interface TrxnOverview {
  datetime: string
  revenue: number
  txnCount: number
  energy: number
}

export interface ChargerProfile {
  time: string
  chargerInUseWeekday: number
  chargerInUseWeekend: number
  chargerInUseWeek: number
}

export interface DailyProfile {
  time: string
  consumeWeekday: number
  consumeWeekend: number
  consumeWeek: number
}

export interface TrxSummary {
  txnCount: number
  txnAfterHr: number
  txnOnDays: number
  revenueTotal: number
  revenueDemandResponse: number
  revenueCharging: number
  revenueLoitering: number
  energyConsumeTotal: number
  energyConsumeInPeakHrs: number
  ghgSavingTotal: number
  chargerAverageDayDuration: number
  chargerAverageDayLevel2Duration: number
  chargerAverageDayDcfcDuration: number
  chargerAverageDayPlugin: number
  chargerAverageDayLevel2Plugin: number
  chargerAverageDayDcfcPlugin: number
  usersTotal: number
  avgTxnDay: number
  avgEnergyConsumePerTxn: number
  avgUserSpend: number
  stationActivation: StationActivation
}

export interface StationActivation {
  mobile: number
  web: number
  rfid: number
  roaming: number
}
