import { useFormatMessage } from '../../helpers/intl'
import configProvider from '../../config'

export const useUserTranslation = () => {
  const emailTxt = useFormatMessage('dashboard.usersPage.table.heading.email', 'Email')
  const searchEmailTxt = useFormatMessage('dashboard.placeholder.searchEmail', 'Search Email')
  const driverInfoTxt = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.driver.info',
    'A user that has access to private listings in',
  )
  const accessInfoTxt = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.driver.info',
    "User's dashboard access to",
  )
  const driverTxt = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.driver', 'Driver')
  const inviteUser = useFormatMessage('dashboard.usersPage.inviteUser', 'INVITE USER')
  const tenantsTxt = useFormatMessage('dashboard.navigation.tenants', 'Sites')
  const userTenantWarningText = useFormatMessage(
    'dashboard.usersPage.user.tenant.warning.info',
    'Note: The user is not associated with all tenants selected',
  )
  const createPlanText = useFormatMessage('dashboard.subscriptionsPage.button.title', 'Create Plan')
  const subscribeUserText = useFormatMessage('dashboard.plan.subscribeUser', 'Subscribe User')
  const editUserText = useFormatMessage('dashboard.usersPage.editUser.heading', 'Edit User')
  const firstNameText = useFormatMessage('dashboard.userspage.editUserName.firstName.heading', 'First Name')
  const lastNameText = useFormatMessage('dashboard.userspage.editUserName.lastName.heading', 'Last Name')
  const sendInviteText = useFormatMessage('dashboard.usersPage.editUser.sendInvite', 'SEND INVITATION')
  const saveText = useFormatMessage('dashboard.usersPage.editUser.save', 'SAVE')
  const tenantsText = useFormatMessage('dashboard.usersPage.editUser.tenants.heading', 'SITE')
  const driverText = useFormatMessage('dashboard.usersPage.editUser.driver.heading', 'DRIVER')
  const roleText = useFormatMessage('dashboard.usersPage.editUser.role.heading', 'ROLE')
  const managerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')
  const chargerOperatorText = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.chargerOperator',
    'Charger Operator',
  )
  const noneText = useFormatMessage('dashboard.usersPage.editUser.none', 'None')
  const addMoreText = useFormatMessage('dashboard.usersPage.editUser.addMore', 'ADD MORE')
  const tooltipText = useFormatMessage(
    'dashboard.usersPage.editUser.driverTooltip',
    'Authorized drivers will gain access to restricted chargers in the site',
  )

  const firstNameRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.firstName.warning',
    'First name is required',
  )
  const lastNameRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.lastName.warning',
    'Last name is required',
  )

  const firstNameMaxLengthRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.firstName.maxlength.warning',
    'First name cannot exceed 20 characters!',
  )

  const lastNameMaxLengthRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.lastName.maxlength.warning',
    'Last name cannot exceed 20 characters!',
  )

  const cancelText = useFormatMessage('dashboard.usersPage.editUser.cancelText', 'CANCEL')
  const updateText = useFormatMessage('dashboard.userspage.editUserName.updateText', 'Update')
  const heading = useFormatMessage('dashboard.usersPage.inviteUser.heading2', 'Invite User')
  const headingInfo = useFormatMessage('dashboard.usersPage.inviteUser.headingInfo', 'Heading Info')
  const emailText = useFormatMessage('dashboard.usersPage.inviteUser.email.heading', 'Email')
  const role2Text = useFormatMessage('dashboard.usersPage.inviteUser.role.heading2', 'ROLE')
  const tenantsInviteText = useFormatMessage('dashboard.usersPage.inviteUser.tenants.heading', 'SITE')
  const driverInviteText = useFormatMessage('dashboard.usersPage.inviteUser.driver.heading', 'DRIVER')
  const addMoreInviteText = useFormatMessage('dashboard.usersPage.inviteUser.addMore', 'ADD MORE')
  const tooltipInviteText = useFormatMessage(
    'dashboard.usersPage.inviteUser.driverTooltip',
    'Authorized drivers will gain access to restricted chargers in the site',
  )
  const managerInviteText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerInviteText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')
  const sendInvite = useFormatMessage('dashboard.usersPage.inviteUser.sendInvite', 'SEND INVITATION')
  const cancelInviteText = useFormatMessage('dashboard.usersPage.inviteUser.cancelText2', 'CANCEL')
  const noneInviteText = useFormatMessage('dashboard.usersPage.inviteUser.none', 'None')
  const idText = useFormatMessage('dashboard.usersPage.table.heading.id', 'Id')
  const userText = useFormatMessage('dashboard.usersPage.table.heading.user', 'User')
  const accessText = useFormatMessage('dashboard.usersPage.table.heading.access', 'Access')
  const noAccessText = useFormatMessage('dashboard.usersPage.table.heading.noaccess', 'No Access')
  const roleTxt = useFormatMessage('dashboard.usersPage.table.heading.role', 'Role')
  const overviewText = useFormatMessage('dashboard.tenantPage.overviewTab.heading', 'Overview')
  const plansText = useFormatMessage('dashboard.text.plans', 'Plans')
  const subscriptionsText = useFormatMessage('dashboard.text.subscriptions', 'Subscriptions')
  const userTypeText = useFormatMessage('dashboard.tenantPage.overviewTab.heading.userType', 'User Type')
  const emailError = useFormatMessage(
    'dashboard.usersPage.emailError',
    `Please enter the email address of the user that has already created a ${configProvider.config.siteTitle} account.`,
  )
  const discountCodeText = useFormatMessage('dashboard.usersPage.inviteUser.discountCode', 'Discount Code')
  const addTenantsText = useFormatMessage('dashboard.usersPage.inviteUser.addTenants', 'Add Sites')
  const discountPlanText = useFormatMessage('dashboard.usersPage.inviteUser.discountPlan', 'Discount Plan')
  const addPermissionsText = useFormatMessage('dashboard.usersPage.inviteUser.addPermissions', 'Add Permissions')

  const guestUserText = useFormatMessage('dashboard.usersPage.guestUser', 'Anonymous Guest')
  return {
    emailTxt,
    searchEmailTxt,
    driverInfoTxt,
    accessInfoTxt,
    driverTxt,
    inviteUser,
    tenantsTxt,
    userTenantWarningText,
    createPlanText,
    subscribeUserText,
    editUserText,
    firstNameText,
    lastNameText,
    sendInviteText,
    saveText,
    tenantsText,
    driverText,
    roleText,
    managerText,
    viewerText,
    chargerOperatorText,
    noneText,
    addMoreText,
    tooltipText,
    firstNameRequiredWarning,
    lastNameRequiredWarning,
    firstNameMaxLengthRequiredWarning,
    lastNameMaxLengthRequiredWarning,
    cancelText,
    updateText,
    heading,
    headingInfo,
    emailText,
    role2Text,
    tenantsInviteText,
    driverInviteText,
    addMoreInviteText,
    tooltipInviteText,
    managerInviteText,
    viewerInviteText,
    sendInvite,
    cancelInviteText,
    noneInviteText,
    idText,
    userText,
    accessText,
    noAccessText,
    roleTxt,
    overviewText,
    plansText,
    subscriptionsText,
    userTypeText,
    emailError,
    discountCodeText,
    addTenantsText,
    discountPlanText,
    addPermissionsText,
    guestUserText,
  }
}
