import { useEffect, useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { useAppState } from '../state'

import { TenantRef } from '../models/tenant'
import { SwtchError } from '../models/error'
import { Transaction, TransactionStartMethod, TransactionStateV2 } from '../models/transaction'
import { TransactionFilter } from '../models/filter'
import { PaginationMeta } from '../models/pagination'

import { GetTransactions, GetTransactionsStats } from '../services/data-provider/transaction'
import { TransactionMetric } from '../models/analytics'
import { isValidEmail } from '../helpers/regularExpression/email'
import { testIfInputIsPositiveNumber } from 'helpers/regularExpression/input'

const initialPagination = { currentPage: 1, perPage: 1, totalEntries: 2 }

export const useTransactions = () => {
  const { selectedTenant, isMockUpEnabled, currentUser } = useAppState()

  const [startDate, setStartDate] = useState(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>(initialPagination)
  const [metrics, setMetrics] = useState<TransactionMetric>()

  const [metricsLoading, setMetricsLoading] = useState(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [exportToken, setExportToken] = useState<string>()

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [showSearchQuery, setShowSearchQuery] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [reset, setReset] = useState(false)
  const [filter, setFilter] = useState<TransactionFilter>({
    page: 1,
    defaultTenant: selectedTenant,
    startMethod: undefined,
    state: undefined,
    // serialNumbers: [],
    userName: undefined,
    masterAccounts: [],
  })

  useEffect(() => {
    if (reset) {
      setTenants([])
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    fetchTransactions()
    fetchTransactionStats()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // return () => {
    //   second
    // }
  }, [filter.page, pagination.currentPage])

  const fetchTransactionsAndStats = () => {
    const isValid = checkValidEmail(filter.email)
    if (!isValid) {
      return
    }

    setFilter({ ...filter, page: 1 })
    setPagination({ ...pagination, currentPage: 1 })
    fetchTransactions()
    fetchTransactionStats()
  }

  const fetchDataWithQuery = () => {
    setShowSearchQuery(true)
    fetchTransactions()
    fetchTransactionStats()
  }

  const fetchTransactions = () => {
    setLoading(true)
    setError(undefined)
    GetTransactions(currentUser, isMockUpEnabled, filter, startDate, endDate)
      .then((resp) => {
        setTransactions(resp.data)
        setPagination(resp.pagination)
        setExportToken(resp.filters.exportToken)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const fetchTransactionStats = () => {
    setError(undefined)
    setMetricsLoading(true)
    GetTransactionsStats(isMockUpEnabled, filter, startDate, endDate)
      .then((metric) => setMetrics(metric))
      .catch((err) => {
        setError((prevState) => {
          if (prevState) {
            let newError = prevState
            newError.messages = prevState.messages || []
            newError.messages.push(...err.messages)
            return newError
          }
          return err
        })
        // setEnableExportButton(false)
      })
      .finally(() => setMetricsLoading(false))
  }

  const handleFilterChange = (filter: TransactionFilter) => {
    setFilter((prevFilters) => ({ ...prevFilters, ...filter }))
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ ...pagination, currentPage: page })
    setFilter({ ...filter, page })
    return
  }

  const handleTenantSelection = (tenants: TenantRef[]) =>
    setFilter({
      ...filter,
      tenants: tenants.filter((obj, index) => {
        return index === tenants.findIndex((o) => obj.id === o.id)
      }),
    })

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setFilter({ ...filter, email: e.target.value })

  const checkValidEmail = (email?: string) => {
    message.destroy()
    if (!email) {
      setDisabled(false)
      return true
    }
    if (isValidEmail(email.trim())) {
      setDisabled(false)
      return true
    } else {
      setDisabled(true)
      message.error('Invalid email')
      return false
    }
  }

  const handleTransactionId = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = e.target
    if (testIfInputIsPositiveNumber(inputValue) || inputValue === '') {
      setFilter({ ...filter, transactionId: inputValue })
    }
  }

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleStartmethod = (startMethod: TransactionStartMethod) => setFilter({ ...filter, startMethod })

  const handleState = (state: TransactionStateV2) => setFilter({ ...filter, state })

  const handleResetAdvancedSearch = () => {
    setDisabled(false)
    setReset(true)
    setFilter({
      ...filter,
      startMethod: undefined,
      state: undefined,
      chargerId: [],
      userName: undefined,
      chargers: [],
      listingTitle: [],
      email: undefined,
      transactionId: undefined,
      tenants: [],
    })
  }

  const handleUsername = (e: string) => {
    setFilter({ ...filter, userName: e })
  }

  const handleChargerId = (chargers: string[]) => {
    setFilter({ ...filter, chargers })
  }

  return {
    handlePaginationChange,
    handleTenantSelection,
    handleChangeDate,
    handleStartmethod,
    handleState,
    handleUsername,
    handleChargerId,
    handleResetAdvancedSearch,
    handleEmail,
    handleTransactionId,
    fetchTransactionsAndStats,
    setTenants,
    setFilter,
    setDisabled,
    checkValidEmail,
    fetchDataWithQuery,
    setReset,
    handleFilterChange,
    reset,
    showSearchQuery,
    disabled,
    startDate,
    endDate,
    metrics,
    metricsLoading,
    exportToken,
    filter,
    tenants,
    transactions,
    pagination,
    loading,
    error,
  }
}
