import { ChargersPerTenantFilter } from '../../models/filter'

export const chargersPerTenantQuery = (f: ChargersPerTenantFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.listings && f.listings.length > 0) {
    queryParams['listing_ids'] = `${f.listings.map((l) => l.id)}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['tenant_ids'] = `${f.tenants.map((t) => t.id)}`
  }

  if (f.masterAccounts && f.masterAccounts.length > 0) {
    queryParams['master_account_ids'] = `${f.masterAccounts.map((m) => m.id)}`
  }

  if (f.chargers && f.chargers.length > 0) {
    queryParams['charger_ids'] = `${f.chargers.map((c) => c.id)}`
  }

  if (f.vendors && f.vendors.length !== 0) {
    queryParams['charger_make'] = f.vendors.join(',')
  }

  if (f.serviceStatuses && f.serviceStatuses.length !== 0) {
    queryParams['connector_service_statuses'] = f.serviceStatuses.join(',')
  }

  if (f.ocppStatuses && f.ocppStatuses.length !== 0) {
    queryParams['ocpp_statuses'] = f.ocppStatuses.join(',')
  }

  if (f.taggings && f.taggings.length !== 0) {
    queryParams['tagging_ids'] = `${f.taggings.map((t) => t.id)}`
  }

  if (f.allListings) {
    queryParams['all_listings'] = `${f.allListings}`
  }

  if (f.connectivityStatus) {
    queryParams['connectivity_status'] = f.connectivityStatus
  }

  if (f.hidden !== undefined) {
    queryParams['hidden'] = `${f.hidden}`
  }

  return queryParams
}
