import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { Links, Paths } from '../../components/routes/paths'
import { useAppState } from '../../state'
import queryString from 'query-string'
import { AsyncComponent } from '../../atom/async-component/async-component'
import { SwtchError } from '../../models/error'
import { LoggedInPath } from '../../components/routes/routes'
import { JWTFromAuth0 } from '../../services/data-provider/auth'
import { Button, Result } from 'antd'
import { Loading } from '../../atom/loader'
import { log } from '../../logger'
import configProvider from '../../config'
import { buildAppUrl } from 'helpers/url'

const onSuccessRedirection = (redirectUrl: string = LoggedInPath) => {
  log('auth login completed correctly, redirecting to user dashboard.', {
    redirectUrl: redirectUrl,
  })
  return <Redirect to={redirectUrl} />
}

const onLoading = (): React.ReactNode => {
  return <Loading size={'large'} />
}

export function Auth0CallbackFunc(props: RouteComponentProps) {
  const { auth0, login, logout } = useAppState()
  const { redirect_path } = queryString.parse(props.location.search)

  const handleAuthentication = async (props: RouteComponentProps) => {
    const queryParams = new URLSearchParams(props.location.search)

    if (/access_token|id_token|error/.test(props.location.hash)) {
      await handleAuth0hash(props.location.hash)
      return
    } else if (queryParams.has('code')) {
      // Handle code in the query string (Authorization Code Flow)
      const code = queryParams.get('code')
      if (code) {
        await handleAuth0Code(code)
        return
      }
    } else if (queryParams.has('error')) {
      // Handle errors in the query string (Authorization Code Flow)
      const error = queryParams.get('error')
      const errorDescription = queryParams.get('error_description')
      throw new Error(`Auth0 Error: ${error} - ${errorDescription}`)
    } else {
      throw new Error('Invalid callback parameters')
    }
  }

  const handleAuth0Code = async (code: string) => {
    try {
      const auth0Domain = configProvider.config.auth0.domain.replace(/^(https?:\/\/)?/, 'https://')
      const response = await fetch(`${auth0Domain}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grant_type: 'authorization_code',
          client_id: configProvider.config.auth0.clientId,
          client_secret: configProvider.config.auth0.clientSecret,
          code,
          redirect_uri: buildAppUrl(Paths.auth0Callback),
        }),
      })

      const authResult = await response.json()

      if (authResult.id_token) {
        const jwt = await JWTFromAuth0(authResult.id_token)
        login(jwt)
        return Links.onboard()
      } else {
        throw new Error('Failed to retrieve tokens')
      }
    } catch (error) {
      log('Unable to retrieve token from auth0 code', { code, err: error })
      throw new Error('Unable to retrieve token from auth0 code')
    }
  }

  const handleAuth0hash = async (hash: string) => {
    return new Promise((resolve, reject) => {
      auth0.parseHash({ hash: props.location.hash }, function (err, authResult) {
        log('completed handling of Auth0 callback url decoding.', {
          authResult: authResult,
          error: err,
        })
        if (authResult?.idToken) {
          JWTFromAuth0(authResult.idToken)
            .then((jwt) => {
              log('completed handling of Auth0 callback url decoding.')
              login(jwt)
              resolve(Links.onboard())
              // history.push(LoggedInPath)
            })
            .catch((error: SwtchError) => {
              log('Unable to retrieve token from auth0 code', {
                token: authResult?.idToken,
                err: error.messages,
              })
              reject(error.messages)
            })
        } else if (err) {
          reject(err)
        }
      })
    })
  }

  const onError = (err: any): React.ReactNode => {
    log('unable to correctly retrieve Auth0 authentication credentials from URL.')
    return (
      <Result
        status="500"
        title="Oops something went wrong"
        subTitle="We were unable to log you in. Try again"
        extra={
          <Button
            type="primary"
            onClick={() => {
              logout()
              window.location.replace(Links.auth0login())
            }}
          >
            Back to login
          </Button>
        }
      />
    )
  }

  return (
    <AsyncComponent
      id={'auth0-'}
      worker={() => handleAuthentication(props) as any}
      onLoading={onLoading}
      onSuccess={() => onSuccessRedirection(redirect_path as string)}
      onError={onError}
    />
  )
}
