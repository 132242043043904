import { useEffect, useState } from 'react'
import { Divider, Form } from 'antd'
import { Tenant } from '../../../models/tenant'
import { CreatePricingModalWrapper } from '../../../atom/price'
import { ChargingSectionModal } from './charging-section'
import { LoiteringSectionModal } from './loitering-section'
import { ConstantsSectionModal } from './constants-section'
import { FooterSectionModal } from './footer-section'
import { usePricingSchemas } from '../../../hooks/usePricingSchemas'
import { NameSectionModal } from './name-section'
import { NewListing } from 'models/listing'
import { PricingSchemaPayload } from 'models/price'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { DiscountSchema } from 'models/discount'

interface props {
  tenant: Tenant
  current: number
  listings: NewListing[]
  onCancel: () => void
  onOk: () => void
  next: () => void
  setListings: (listings: NewListing[]) => void
  onFinish: (values: PricingSchemaPayload, modal: string, listings: NewListing[], nextPageInModal?: boolean) => void
  setPricingSchemaValues: (pricingSchemaValues: PricingSchemaPayload) => void
  defaultListings?: NewListing[]
  onlyPricing?: boolean
  hideMaxLimit?: boolean
  discounts?: DiscountSchema[]
}

const CreatePricingSchemaModal: React.FC<props> = ({
  tenant,
  current,
  listings,
  onCancel,
  onOk,
  next,
  setListings,
  setPricingSchemaValues,
  defaultListings,
  onlyPricing = false,
  hideMaxLimit = false,
  discounts,
}) => {
  const [form] = Form.useForm()
  const [currentListingHasDiscount, setCurrentListingHasDiscount] = useState(false)

  const {
    loading,
    initialValues,
    nameExists,
    eventSchedules,
    timeOfDay,
    timeOfDayLoitering,
    enableCurtailmentAdjustment,
    loiteringType,
    allListings,
    pricingType,
    nextInModal,
    setLoiteringType,
    setEnableCurtailmentAdjustment,
    nameCheck,
    getPricingNames,
    setPricePerUnitLoitering,
    setPricePerUnit,
    setTimeOfDay,
    setTimeOfDayLoitering,
    setAllListings,
    setPricingType,
    setNextInModal,
    onFinish,
  } = usePricingSchemas(tenant, onOk, onCancel)
  const { loiteringText } = usePricingSchemasTranslation()

  useEffect(() => {
    if (nextInModal) {
      setPricingSchemaValues({
        ...form.getFieldsValue(true),
        enable_time_of_day: timeOfDay,
        enable_time_of_day_loitering: timeOfDayLoitering,
      })
      onFinish(form.getFieldsValue(true), 'createPricingSchema', listings, true)
      onOk()
      next()
    }
    setNextInModal(false)
  }, [nextInModal])

  useEffect(() => {
    getPricingNames(Number(tenant.id))
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    if (discounts && listings) {
      let hasDiscount = false
      discounts.forEach((discount) => {
        if (
          discount?.appliedListings?.some((appliedListing) =>
            listings.some((listing) => listing.id === appliedListing.listingId),
          )
        ) {
          hasDiscount = true
          return
        }
      })
      setCurrentListingHasDiscount(hasDiscount)
    }
  }, [discounts, listings])

  return (
    <CreatePricingModalWrapper>
      <Form
        colon={false}
        form={form}
        layout="horizontal"
        requiredMark={true}
        labelAlign="left"
        initialValues={initialValues}
        onFinish={(values) => {
          let missedOutValues = {}
          if (hideMaxLimit) {
            missedOutValues = {
              loitering_maximum: initialValues.loitering_maximum,
              payment_limit: initialValues.payment_limit,
            }
          }
          setPricingSchemaValues({
            ...values,
            enable_time_of_day: timeOfDay,
            enable_time_of_day_loitering: timeOfDayLoitering,
            ...missedOutValues,
          })
          onFinish({ ...values, ...missedOutValues }, 'createPricingSchema', listings)
        }}
        labelWrap={true}
      >
        <NameSectionModal
          tenant={tenant}
          listings={listings}
          defaultListings={defaultListings}
          nameExists={nameExists}
          allListings={allListings}
          type="create"
          nameCheck={nameCheck}
          getPricingNames={getPricingNames}
          setListings={setListings}
          setAllListings={setAllListings}
        />
        <ChargingSectionModal
          form={form}
          isCanadaSite={tenant?.location?.country === 'CA' || tenant?.country === 'CA'}
          timeOfDay={timeOfDay}
          eventSchedules={eventSchedules}
          enableCurtailmentAdjustment={enableCurtailmentAdjustment}
          priceType={pricingType}
          setPricePerUnit={setPricePerUnit}
          setTimeOfDay={setTimeOfDay}
          setEnableCurtailmentAdjustment={setEnableCurtailmentAdjustment}
          setPriceType={setPricingType}
          hideMaxLimit={hideMaxLimit}
        />
        <Divider>
          <span className="paragraph-02-bold">{loiteringText}</span>
        </Divider>
        <LoiteringSectionModal
          form={form}
          loiteringType={loiteringType}
          timeOfDayLoitering={timeOfDayLoitering}
          eventSchedules={eventSchedules}
          setPricePerUnitLoitering={setPricePerUnitLoitering}
          setTimeOfDayLoitering={setTimeOfDayLoitering}
          setLoiteringType={setLoiteringType}
          hideMaxLimit={hideMaxLimit}
        />
        <ConstantsSectionModal hideMaxLimit={hideMaxLimit} />
        <FooterSectionModal
          loading={loading}
          onlyPricing={onlyPricing}
          form={form}
          nameExists={nameExists}
          current={current}
          type="create"
          onCancel={onCancel}
          setNextInModal={setNextInModal}
          hasDiscountPlan={currentListingHasDiscount}
        />
      </Form>
    </CreatePricingModalWrapper>
  )
}

export default CreatePricingSchemaModal
