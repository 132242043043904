import { Form, Modal } from 'antd'
import { Tenant } from '../../../models/tenant'
import { usePricingSchemas } from '../../../hooks/usePricingSchemas'
import { StepperTitle } from './stepper-title'
import CreatePricingSchemaModal from './create-pricing-schema-modal'
import CreateDiscountSchemaModal from './create-discount-schema-modal'
import { useState } from 'react'
import { PricingSchemaPayload } from 'models/price'
import { DiscountSchema } from 'models/discount'
import { NewListing } from 'models/listing'

interface props {
  tenant: Tenant
  discounts?: DiscountSchema[]
  onCancel: () => void
  onOk: () => void
  onlyPricing?: boolean
  defaultListings?: NewListing[]
  hideMaxLimit?: boolean
}

const CreatePricingAndDiscountSchemaModal: React.FC<props> = ({
  tenant,
  discounts = [],
  onCancel,
  onOk,
  onlyPricing = false,
  defaultListings,
  hideMaxLimit = false,
}) => {
  const [form] = Form.useForm()
  const { loading, current, listings, next, setListings, onFinish } = usePricingSchemas(tenant, onOk, onCancel)
  const afterClose = () => form.resetFields()
  const [pricingSchemaValues, setPricingSchemaValues] = useState<PricingSchemaPayload>()

  return (
    <Modal
      title={<StepperTitle current={current} onlyPricing={onlyPricing} />}
      visible={true}
      onCancel={onCancel}
      width={1034}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={null}
    >
      {current === 0 ? (
        <CreatePricingSchemaModal
          tenant={tenant}
          defaultListings={defaultListings}
          current={current}
          listings={listings}
          onCancel={onCancel}
          onOk={onOk}
          next={next}
          setListings={setListings}
          onFinish={onFinish}
          setPricingSchemaValues={setPricingSchemaValues}
          onlyPricing={onlyPricing}
          hideMaxLimit={hideMaxLimit}
          discounts={discounts}
        />
      ) : (
        <CreateDiscountSchemaModal
          tenant={tenant}
          listings={listings}
          pricingSchemaValues={pricingSchemaValues}
          discounts={discounts}
          modalType="both"
          onCancel={onCancel}
          onOk={onOk}
        />
      )}
    </Modal>
  )
}

export default CreatePricingAndDiscountSchemaModal
