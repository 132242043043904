import React from 'react'

import { Button, Modal } from 'antd'
import { getHighlightedText, useFormatMessage } from 'helpers/intl'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import timeBasedLoiteringImage from 'assets/images/time-based-loitering-info.png'
import statusBasedLoiteringImage from 'assets/images/status-based-loitering-info.png'
import { useAppState } from 'state'

interface props {
  visible: boolean
  onClose: () => void
}

export const LoiteringInfoModal: React.FC<props> = ({ visible, onClose }) => {
  const {
    loiteringInfoP1Text,
    loiteringInfoP2Text,
    loiteringInfoP3Text,
    loiteringInfoP4Text,
    loiteringInfoP5Text,
    loiteringInfoP6Text,
    loiteringInfoP7Text,
  } = usePricingSchemasTranslation()

  const { language } = useAppState()

  const loiteringInfo = [
    {
      description: loiteringInfoP1Text,
    },
    {
      description: loiteringInfoP2Text,
      highlightedText: language === 'en' ? 'Time-based Loitering' : 'Loitering basé sur le temps',
    },
    {
      description: loiteringInfoP3Text,
    },

    {
      description: loiteringInfoP4Text,
    },
    {
      image: timeBasedLoiteringImage,
    },
    {
      description: loiteringInfoP5Text,
      highlightedText: language === 'en' ? 'Status-based Loitering' : 'Loitering basé sur le statut',
    },
    {
      description: loiteringInfoP6Text,
    },
    {
      description: loiteringInfoP7Text,
    },
    {
      image: statusBasedLoiteringImage,
    },
  ]

  return (
    <Modal
      visible={visible}
      footer={
        <Button type="primary" onClick={() => onClose()}>
          <span className="paragraph-02-regular">
            {useFormatMessage('dashboard.homePage.energyConsumptions.info.okbtn', 'Ok')}
          </span>
        </Button>
      }
      className="ghg-calcuation-modal"
      onCancel={onClose}
    >
      {loiteringInfo.map((item, index) => (
        <div key={index}>
          {item.description && (
            <p className="heading-06-regular">
              {getHighlightedText(item.description, item?.highlightedText, 'heading-05-regular')}
            </p>
          )}
          {item.image && (
            <img src={item.image} alt={`lointering-info-${index}`} style={{ width: '100%', marginBottom: 20 }} />
          )}
        </div>
      ))}
    </Modal>
  )
}

export default LoiteringInfoModal
