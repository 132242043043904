import { message, Select, Tag } from 'antd'
import { ReactElement, useEffect, useState } from 'react'

import { renderFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'
import { useSelector } from 'hooks/useSelector'
import { useAppState } from 'state'

const { Option } = Select

interface props {
  defaultTenant?: TenantRef
  onOptionsChange: (options: TenantRef[]) => void
  onClear: () => void
}

const renderOption = (tenant: TenantRef, formatter: (value: string) => string): ReactElement => {
  return (
    <Option key={tenant.id} value={formatter(tenant.combineName)}>
      {formatter(tenant.combineName)}
    </Option>
  )
}

export const MultiTenantSelector: React.FC<props> = ({ defaultTenant, onOptionsChange, onClear }) => {
  const { isMockUpEnabled, currentUser } = useAppState()
  const [selected, setSelected] = useState<TenantRef | undefined>(defaultTenant)
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [displayCount, setDisplayCount] = useState(false)
  const { siteLabelCleaner } = useSelector()

  const handleChange = (values: string[]) => {
    values.length > 1 ? setDisplayCount(true) : setDisplayCount(false)
    onOptionsChange(
      tenants.filter((t) => values.map((val) => siteLabelCleaner(val)).includes(siteLabelCleaner(t.combineName))),
    )
  }

  useEffect(() => {
    FindTenants(currentUser, false, isMockUpEnabled)
      .then((tenants) => setTenants(tenants))
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
      })
  }, [])

  return (
    <>
      {!selected && (
        <Select
          showSearch
          mode="multiple"
          allowClear
          style={{ minWidth: 159, width: '100%' }}
          placeholder={renderFormatMessage('dashboard.selector.searchTenants', 'Search Site(s)')}
          onClear={onClear}
          onChange={handleChange}
          dropdownMatchSelectWidth={false}
          maxTagCount={displayCount ? 'responsive' : undefined}
        >
          {tenants.length !== 0 && tenants.map((v) => renderOption(v, siteLabelCleaner))}
        </Select>
      )}

      {selected && (
        <Tag
          closable={true}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
          }}
        >
          {siteLabelCleaner(selected.combineName)}
        </Tag>
      )}
    </>
  )
}
