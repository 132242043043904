import { useParams } from 'react-router-dom'
import { Spin, Table } from 'antd'
import { useEffect, useState } from 'react'
import { Tenant } from '../../models/tenant'
import { AlertError } from '../error'
import { PricingSchemaColumns } from './table/pricing-schema-columns'
import { ExpandedRowRender } from './table/discount-schema-table'
import { Box } from '../../atom/box'
import { PricingSchemaTableWrapper } from '../../atom/price'
import { usePricingSchemas } from '../../hooks/usePricingSchemas'
import EditPricingSchemaModal from './modal/edit-pricing-schema-modal'
import { ListingInfo, PricingSchema } from '../../models/price'
import { GetDiscounts } from 'services/data-provider/discount'
import { downArrowSVG, upArrowSVG } from 'assets/svg/arrow'
import CreatePricingAndDiscountSchemaModal from './modal/create-pricing-and-discount-modal'
import CreateDiscountOnlySchemaModal from './modal/create-discount-only-schema-modal'
import EditDiscountSchemaModal from './modal/discount-schema/edit-discount-schema-modal'
import { useDiscountSchemas } from 'hooks/useDiscountSchemas'
import { SwtchError } from 'models/error'
import { DiscountSchema } from 'models/discount'
import { useAppState } from 'state'

interface tenantTabDetailProps {
  tenant: Tenant
  showCreatePricingSchemaModal: boolean
  showCreateDiscountSchemaModal: boolean
  setShowCreatePricingSchemaModal: (showCreatePricingSchemaModal: boolean) => void
  setShowCreateDiscountSchemaModal: (showCreateDiscountSchemaModal: boolean) => void
}

// TODO: refactor this page using SOLID principle and atomic design
export const TenantTabPricingSchema: React.FC<tenantTabDetailProps> = ({
  tenant,
  showCreatePricingSchemaModal,
  showCreateDiscountSchemaModal,
  setShowCreatePricingSchemaModal,
  setShowCreateDiscountSchemaModal,
}) => {
  document.querySelector('body')?.classList.add('redesignActive')
  // @ts-ignore
  const { tenantId } = useParams<{ tenantId: number }>()
  const [selectedPricingSchema, setSelectedPricingSchema] = useState<PricingSchema | null>()
  const [reloadPage, setReloadPage] = useState(false)
  const [discounts, setDiscounts] = useState<DiscountSchema[]>([])
  const [selectedDiscountSchema, setSelectedDiscountSchema] = useState<any>()

  const { isMockUpEnabled } = useAppState()

  const {
    loading,
    error,
    currentPage,
    pricingSchemaData,
    pagination,
    onPaginationChange,
    getPricings,
  } = usePricingSchemas(tenant)

  const { discountId, convertPricingSchemaToPayload, archiveDiscountSchema, setDiscountId } = useDiscountSchemas(
    tenant,
    () => {
      // onOk
      setSelectedPricingSchema(null)
      getPricings(currentPage, tenantId)
      GetDiscounts(isMockUpEnabled, tenantId)
        .then((resp) => setDiscounts(resp))
        .catch((err: SwtchError) => console.log('error', err))
    },
    () => {
      //onCancel
      setSelectedPricingSchema(null)
    },
  )

  useEffect(() => {
    getPricings(currentPage, tenantId)
    if (reloadPage) setReloadPage(false)
  }, [currentPage, reloadPage])

  const loadDiscounts = async () => {
    GetDiscounts(isMockUpEnabled, tenantId)
      .then((resp: DiscountSchema[]) => setDiscounts(resp))
      .catch((err: SwtchError) => console.log('error', err))
  }

  useEffect(() => {
    loadDiscounts()
  }, [])

  const [expanded, setExpanded] = useState<{ [dataKey: string]: boolean }>({})
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: string]: boolean }>({})

  const handleRowExpand = (rowId: number) => () => {
    let updatedExpanded = { ...expanded }
    updatedExpanded[rowId] = !updatedExpanded[rowId]
    setExpanded(updatedExpanded)
  }
  const rowExpandable = () => {
    let updatedExpandableRowKeys = { ...expandableRowKeys }
    pricingSchemaData?.forEach((record) => {
      if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return
      const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)
      const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight + 8 - 16 * 2 //padding is 16 px for each cell
      const childElements = rowElement?.querySelectorAll('.custom-row-collapse-pricing-plan > td > *')

      let expandable = false

      childElements?.forEach((el: Element) => {
        expandable = expandable || el.scrollHeight > currentRowHeight
      })
      updatedExpandableRowKeys[record.id] = expandable
    })
    setExpandableRowKeys(updatedExpandableRowKeys)
  }

  const displayDiscountTable = (arr1: ListingInfo[] | undefined, arr2: ListingInfo[] | undefined) => {
    const set = new Set(arr1?.map((item) => item.listingId))
    return arr2?.some((item) => set.has(item.listingId))
  }

  useEffect(() => {
    rowExpandable()
  }, [pricingSchemaData])

  return (
    <Spin spinning={loading}>
      <AlertError error={error} />
      <Box padding>
        <PricingSchemaTableWrapper>
          <Table
            columns={PricingSchemaColumns(setSelectedPricingSchema, expandableRowKeys, expanded, handleRowExpand)}
            dataSource={pricingSchemaData}
            rowKey={(record) => record.id}
            rowClassName={(record) => (expanded[record.id] ? '' : 'custom-row-collapse-pricing-plan')}
            expandable={{
              expandedRowRender: (record, index, indent, expanded) => {
                let show = false
                record.appliedListings &&
                  discounts.map((discount) => {
                    if (displayDiscountTable(record.appliedListings, discount.appliedListings)) show = true
                    return show
                  })
                return (
                  show && (
                    <ExpandedRowRender
                      loadDiscounts={loadDiscounts}
                      discounts={discounts}
                      index={index}
                      expanded={expanded}
                      pricingSchema={record}
                      setSelectedPricingSchema={setSelectedPricingSchema}
                      setSelectedDiscountSchema={setSelectedDiscountSchema}
                      archiveDiscountSchema={archiveDiscountSchema}
                      setDiscountId={setDiscountId}
                    />
                  )
                )
              },
              defaultExpandedRowKeys: ['0'],
              expandIcon: ({ expanded, onExpand, record }) => {
                let show = false
                record.appliedListings &&
                  discounts.map((discount) => {
                    if (displayDiscountTable(record.appliedListings, discount.appliedListings)) show = true
                    return show
                  })
                return (
                  show &&
                  (expanded ? (
                    <div onClick={(e) => onExpand(record, e)}>{upArrowSVG}</div>
                  ) : (
                    <div onClick={(e) => onExpand(record, e)}>{downArrowSVG}</div>
                  ))
                )
              },
              expandedRowClassName: () => 'discount-schema-info',
            }}
            pagination={{
              position: ['bottomCenter'],
              total: pagination?.totalEntries,
              current: pagination?.currentPage,
              pageSize: pagination?.perPage,
              showSizeChanger: false,
              onChange: onPaginationChange,
            }}
          />
        </PricingSchemaTableWrapper>
        {showCreatePricingSchemaModal && (
          <CreatePricingAndDiscountSchemaModal
            tenant={tenant}
            discounts={discounts}
            onCancel={() => setShowCreatePricingSchemaModal(false)}
            onOk={() => {
              getPricings(currentPage, tenantId)
              GetDiscounts(isMockUpEnabled, tenantId)
                .then((resp) => setDiscounts(resp))
                .catch((err: SwtchError) => console.log('error', err))
            }}
          />
        )}
        {showCreateDiscountSchemaModal && (
          <CreateDiscountOnlySchemaModal
            tenant={tenant}
            discounts={discounts}
            pricingSchemaData={pricingSchemaData}
            onCancel={() => setShowCreateDiscountSchemaModal(false)}
            onOk={() => {
              getPricings(currentPage, tenantId)
              GetDiscounts(isMockUpEnabled, tenantId)
                .then((resp: DiscountSchema[]) => {
                  setDiscounts(resp)
                })
                .catch((err: SwtchError) => console.log('error', err))
            }}
          />
        )}
        {selectedPricingSchema && !selectedDiscountSchema && (
          <EditPricingSchemaModal
            tenant={tenant}
            onCancel={() => setSelectedPricingSchema(null)}
            selectedPricingSchema={selectedPricingSchema}
            onOk={() => {
              setSelectedPricingSchema(null)
              getPricings(currentPage, tenantId)
            }}
            discounts={discounts}
          />
        )}
        {selectedDiscountSchema && (
          <EditDiscountSchemaModal
            tenant={tenant}
            selectedPricingSchema={
              selectedPricingSchema ? convertPricingSchemaToPayload(selectedPricingSchema, 'both') : undefined
            }
            discounts={discounts}
            discountId={discountId}
            onCancel={() => {
              setSelectedPricingSchema(null)
              setSelectedDiscountSchema(null)
            }}
            selectedDiscountSchema={selectedDiscountSchema}
            onOk={() => {
              setSelectedPricingSchema(null)
              setSelectedDiscountSchema(null)
              getPricings(currentPage, tenantId)
              GetDiscounts(isMockUpEnabled, tenantId)
                .then((resp: DiscountSchema[]) => {
                  setDiscounts(resp)
                })
                .catch((err: SwtchError) => console.log('error', err))
            }}
          />
        )}
      </Box>
    </Spin>
  )
}
